import http from 'src/api/http';
import qs from 'query-string';

import type { ResponseType } from 'src/api/http';
import type { FindAndCountMetaType, IUser } from 'src/types';
import type { ChannelMetaDataType, ChannelType, IChannel, IChannelDelta, IMessage, IPreviewMessage, UserToThreadType } from 'src/types/chatTypes';
import type { GetParamsType } from './http/http.types';

export type CreateChannelType = {
  name: string;
  description?: string;
  icon?: string;
  isPrivate?: boolean;
  limitedAccess?: boolean;
  writeAccessUsersIds?: number[];
};

export type EditChannelType = {
  name?: string;
  description?: string;
  isPrivate?: boolean;
  icon?: string;
  limitedAccess?: boolean;
  writeAccessUsersIds?: number[];
};

type EditMessageType = {
  messageText: string;
  addUserMentionsIds?: number[];
  removeUserMentionsIds?: number[];
};

type AddUserToChannelType = {
  userIds: number[];
};

type ReassignOwnerToChannelType = {
  userId: number;
};

type HasMoreMetaType = {
  hasMoreUp: boolean;
  hasMoreDown: boolean;
};

export type GetMessagesParamsType = {
  channelId: number;
  sideMessagesCount: number;
  byMessageId?: number;
  byFirstUnreadMessage?: boolean;
};

export type GetChannelWithPinnedType = {
  id: number;
  shouldSetVisible?: boolean;
  abortSignal?: AbortSignal;
};

const channelPath = 'channels';
const searchPath = 'search';
const messagesPath = 'messages';

const getChannelWithPinned = (data: GetChannelWithPinnedType) => {
  const { id, shouldSetVisible, abortSignal } = data;
  const params = {
    shouldSetVisible: !!shouldSetVisible,
    withMeta: true,
  };
  return http.get<ResponseType<IChannel & { pinnedMessagesIds?: number[] }>>(`${channelPath}/with-pinned/${id}`, { params, signal: abortSignal });
};

const getArchivalChannel = () => {
  return http.get<ResponseType<IChannel[]>>(`${channelPath}/archival`);
};

const createChannel = (data: CreateChannelType) => {
  return http.post<ResponseType<ChannelType>>(`${channelPath}/create`, data);
};

const editChannel = (id: number, data: EditChannelType) => {
  return http.patch<ResponseType<ChannelType>>(`${channelPath}/edit/${id}`, data);
};

const leaveChannel = (id: number) => {
  return http.post(`${channelPath}/leave/${id}`);
};

const changeChannelArchiveStatus = (id: number) => {
  return http.patch(`${channelPath}/${id}/toggle-archive-status`);
};

const changeAllChannelArchiveStatus = () => {
  return http.patch(`${channelPath}/cancel-archive-all`);
};

const removeAllArchiveChannel = () => {
  return http.delete(`${channelPath}/remove-all-archived`);
};

const deleteChannel = (id: number) => {
  return http.delete(`${channelPath}/delete/${id}`);
};

const addUsersToChannel = (id: number, data: AddUserToChannelType) => {
  return http.post<ResponseType<IChannel>>(`${channelPath}/add-users/${id}`, data);
};

const joinToChannel = (id: number) => {
  return http.post<ResponseType<IChannel>>(`${channelPath}/join/${id}`);
};

const reassignOwnerToChannel = (id: number, data: ReassignOwnerToChannelType) => {
  return http.patch<ResponseType<IChannelDelta>>(`${channelPath}/reassign-owner/${id}`, data);
};

const removeUsersFromChannel = (id: number, data: AddUserToChannelType) => {
  return http.delete<ResponseType<IChannel>>(`${channelPath}/remove-user/${id}`, { data });
};

const findUsersToAddInChannel = (id: number, data: { keyword: string }) => {
  return http.post<ResponseType<IUser[]>>(`${channelPath}/find-users-to-add/${id}`, data);
};

const getUserSearchResults = (args: GetParamsType, config?: { signal?: AbortSignal }) => {
  const params = {
    page: args.page,
    limit: args.perPage,
    q: args.search,
  };
  return http.get<ResponseType<IUser[], FindAndCountMetaType>>(`${searchPath}/users`, { params, signal: config?.signal });
};

const getChannelSearchResults = (
  args: GetParamsType & { withPagination?: boolean; showMyChannels?: boolean },
  config?: { signal?: AbortSignal },
) => {
  const params = {
    page: args.page,
    limit: args.perPage,
    q: args.search,
    withPagination: args.withPagination,
    showMyChannels: args.showMyChannels,
  };
  return http.get<ResponseType<IChannel[], FindAndCountMetaType>>(`${searchPath}/channels`, { params, signal: config?.signal });
};

const getMessageSearchResults = (args: GetParamsType, config?: { signal?: AbortSignal }) => {
  const params = {
    page: args.page,
    limit: args.perPage,
    q: args.search,
  };
  return http.get<ResponseType<IMessage[], FindAndCountMetaType>>(`${searchPath}/${messagesPath}`, { params, signal: config?.signal });
};

const getMessages = (
  params: GetMessagesParamsType,
) => {
  return http.get<ResponseType<IMessage[], HasMoreMetaType>>(`${messagesPath}/redirect-to/`, { params });
};

const editMessage = (messageId: number, data: EditMessageType) => {
  return http.patch<ResponseType<IMessage>>(`${messagesPath}/${messageId}`, data);
};

const togglePinMessage = (messageId: number, channelId: number, signal?: AbortSignal) => {
  return http.post<ResponseType<IMessage>>(`${messagesPath}/pin-message/${messageId}`, { channelId }, { signal });
};

const readMessage = (data: { messageId: number; channelId: number }) => {
  return http.patch<ResponseType<{
    lastViewedMessageTime: string | Date;
    userId: number;
  }>>(`${channelPath}/read-message`, data);
};

const getChannelPinnedMessages = (id: number) => {
  return http.get<ResponseType<IMessage[]>>(`${channelPath}/${id}/pinned-messages`);
};

const getUserChannels = () => {
  return http.get<ResponseType<{
    channels: IChannel[];
    dmChannels: IChannel[];
    archivedChannels: IChannel[];
  }, { channelsMeta: Record<string, ChannelMetaDataType> }>>(`${channelPath}/users-channels`);
};

const getChannelMessages = (data: {
  channelId: number;
  limit?: number;
  unreadMessagesCount?: number;
  messageId?: number;
  downAnchor?: string | Date;
  upAnchor?: string | Date;
}) => {
  const { channelId, ...params } = data;
  return http.get<ResponseType<IMessage[], {
    hasMoreUp?: boolean;
    hasMoreDown?: boolean;
    firstUnreadMessageId?: number | null;
  }>>(`${channelPath}/${channelId}/messages`, {
    params,
  });
};

const getThreadMessages = (params: { parentMessageId: number }) => {
  return http.get<ResponseType<IMessage[], {
    firstUnreadMessageId?: number | null;
    threadUsers: UserToThreadType[];
  }>>(`${messagesPath}/${params.parentMessageId}/child-messages`);
};

const getMessagesByIds = (data: { ids: (number | string)[] }, signal?: AbortSignal) => {
  return http.get<ResponseType<IPreviewMessage[]>>(`${messagesPath}/list-by-ids`, {
    params: data,
    signal,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
  });
};

export default {
  getUserChannels,
  getChannelMessages,
  createChannel,
  editChannel,
  changeChannelArchiveStatus,
  changeAllChannelArchiveStatus,
  removeAllArchiveChannel,
  deleteChannel,
  addUsersToChannel,
  reassignOwnerToChannel,
  removeUsersFromChannel,
  findUsersToAddInChannel,
  getUserSearchResults,
  getChannelSearchResults,
  getMessageSearchResults,
  getArchivalChannel,
  getChannelWithPinned,
  leaveChannel,
  getMessages,
  editMessage,
  togglePinMessage,
  readMessage,
  joinToChannel,
  getChannelPinnedMessages,
  getThreadMessages,
  getMessagesByIds,
};

import React from 'react';

import type { RouteProps } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';

import Protector from 'src/ui/components/Protector';
import Loader from 'src/ui/components/Loader';

import { useAppSelector } from 'src/store';
import type { AuthPropsType } from 'src/types';
import { ROUTES } from 'src/utils/constants';

const SignInPage = React.lazy(() => import('src/ui/pages/auth/SignInPage'));
const SignUpPage = React.lazy(() => import('src/ui/pages/auth/SignUpPage'));
const ConfirmInvitedUserPage = React.lazy(() => import('src/ui/pages/auth/ConfirmInvitedUserPage'));
const HomePage = React.lazy(() => import('src/legacy/pages/Home'));
const PageNotFound = React.lazy(() => import('src/ui/pages/PageNotFound'));
const Employees = React.lazy(() => import('src/ui/pages/hrm/Employees'));
const CreateUser = React.lazy(() => import('src/ui/pages/hrm/CreateUser'));
const CreateRequest = React.lazy(() => import('src/legacy/pages/Requests'));
const ListOfRequests = React.lazy(() => import('src/legacy/pages/ListOfRequests'));
const ChatPage = React.lazy(() => import('src/ui/pages/Chat'));
const ExtraHours = React.lazy(() => import('src/legacy/pages/ExtraHours'));
const EventsCalendar = React.lazy(() => import('src/legacy/pages/EventsCalendar'));
const Inventory = React.lazy(() => import('src/legacy/pages/Inventory'));
const TermsPage = React.lazy(() => import('src/ui/pages/auth/TermsPage'));
const CreateWorkspace = React.lazy(() => import('src/ui/components/CreateWorkspace'));
const ChooseWorkspace = React.lazy(() => import('src/ui/components/ChooseWorkspace'));
const Profile = React.lazy(() => import('src/ui/pages/hrm/Profile'));
const WorkspaceSettings = React.lazy(() => import('src/ui/pages/Workspace'));

const Router: React.FC = () => {
  const user = useAppSelector(({ main }) => main.user);
  const isWorkspacesSelected = useAppSelector(({ main }) => Boolean(main.selectedWorkspaces.length));
  const usersCollectionStatus = useAppSelector(({ usersCollection }) => usersCollection.status);

  const availableRoutes = React.useMemo(() => {
    if (!user) {
      return authRoutes;
    }

    if (user && !isWorkspacesSelected && usersCollectionStatus === 'success') {
      return [...authRoutes, ...routesWithoutWorkspace];
    }

    return [...routes, ...routesWithoutWorkspace];
  }, [user, isWorkspacesSelected, usersCollectionStatus]);

  return (
    <React.Suspense fallback={<Loader isFixed />}>
      <Routes>
        {availableRoutes.map((route) => (
          <Route
            key={route.path}
            {...route}
          />
        ))}

        {user
          ? (
            <Route
              path={ROUTES.root.path}
              element={<Navigate to={ROUTES.chatGeneral.createPath()} />}
            />
          )
          : null}

        <Route
          path="*"
          element={<PageNotFound />}
        />
      </Routes>
    </React.Suspense>
  );
};

type RawRouteType = {
  path: string;
  component: React.LazyExoticComponent<React.FC>;
  auth?: AuthPropsType;
  getFallBackNode?: (id?: number) => React.ReactNode;
};

const withProtection = (rawRoutesArr: RawRouteType[]) => {
  return rawRoutesArr.map((route): RouteProps => {
    const Page = route.component;
    const element = (
      <Protector
        {...route.auth}
        key={route.path}
        getFallbackNode={route.getFallBackNode}
      >
        <Page />
      </Protector>
    );

    return {
      path: route.path,
      element,
    };
  });
};

const rawRoutes: RawRouteType[] = [
  { path: ROUTES.home.path, auth: ROUTES.home.auth, component: HomePage },
  {
    path: ROUTES.employees.path,
    auth: ROUTES.employees.auth,
    component: Employees,
    getFallBackNode: (id?: number) => <Navigate to={ROUTES.chat.createPath(id)} />,
  },
  { path: ROUTES.createRequest.path, auth: ROUTES.createRequest.auth, component: CreateRequest },
  { path: ROUTES.requestsList.path, auth: ROUTES.requestsList.auth, component: ListOfRequests },
  { path: ROUTES.chat.path, auth: ROUTES.chat.auth, component: ChatPage },
  { path: ROUTES.extraHours.path, auth: ROUTES.extraHours.auth, component: ExtraHours },
  { path: ROUTES.eventsCalendar.path, auth: ROUTES.eventsCalendar.auth, component: EventsCalendar },
  { path: ROUTES.events.path, auth: ROUTES.events.auth, component: EventsCalendar },
  { path: ROUTES.inventory.path, auth: ROUTES.inventory.auth, component: Inventory },
  { path: ROUTES.profile.path, auth: ROUTES.profile.auth, component: Profile },
  { path: ROUTES.createUser.path, auth: ROUTES.createUser.auth, component: CreateUser },
  {
    path: ROUTES.workspaceSettings.path,
    auth: ROUTES.workspaceSettings.auth,
    component: WorkspaceSettings,
  },
];

const routes = withProtection(rawRoutes);

const routesWithoutWorkspace = withProtection([
  {
    path: ROUTES.createWorkspace.path,
    auth: ROUTES.createWorkspace.auth,
    component: CreateWorkspace,
  },
  {
    path: ROUTES.choiceWorkspace.path,
    auth: ROUTES.choiceWorkspace.auth,
    component: ChooseWorkspace,
  },
  {
    path: ROUTES.confirmInvitedUser.path,
    auth: {
      isAuthRequired: true,
      alwaysAvailable: true,
    },
    component: ConfirmInvitedUserPage,
  },
  { path: ROUTES.terms.path, component: TermsPage },
]);

const rawAuthRoutes: RawRouteType[] = [
  { path: ROUTES.signUp.path, component: SignUpPage },
  { path: ROUTES.terms.path, component: TermsPage },
  {
    path: ROUTES.confirmInvitedUser.path,
    auth: ROUTES.confirmInvitedUser.auth,
    component: ConfirmInvitedUserPage,
  },

  { path: '*', component: SignInPage },
];

const authRoutes: RouteProps[] = rawAuthRoutes.map((route) => {
  const Component = route.component;
  return {
    path: route.path,
    element: <Component />,
  };
});

export default Router;

import React from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import socket from 'src/api/ws/socket';
import { useAppSelector } from 'src/store/store';
import { useUser } from './general';
import { ElectronAPI } from '../electronAPI';
import { ROUTES } from '../constants';

const useSocketConnect = () => {
  const location = useLocation();
  const user = useUser();
  const isSocketConnected = useAppSelector((state) => state.main.socketData.isConnected);
  const isNetworkOnline = useNetworkOnlineInfo();
  const isChatPath = useMatch(ROUTES.chatGeneral.path);
  const isHRMPath = useMatch(ROUTES.hrm.path);

  const isLocationMatchAllowed = isChatPath || isHRMPath;

  const isConnectToSocket = user && isLocationMatchAllowed;

  React.useEffect(() => {
    if (isConnectToSocket && !isSocketConnected && ElectronAPI.systemStateInNormalMode) {
      console.info('>>> Connecting to socket <<<', new Date().toLocaleString());
      socket.connect();
    }
  }, [location, isConnectToSocket, isSocketConnected, isNetworkOnline]);
};

export const useNetworkOnlineInfo = () => {
  const [isNetworkTurnOnline, setIsNetworkTurnOnline] = React.useState(navigator.onLine);

  React.useEffect(() => {
    const updateNetState = () => {
      setIsNetworkTurnOnline(navigator.onLine);
    };
    window.addEventListener('online', updateNetState);
    window.addEventListener('offline', updateNetState);

    return () => {
      window.removeEventListener('online', updateNetState);
      window.removeEventListener('offline', updateNetState);
    };
  }, []);

  return isNetworkTurnOnline;
};

export default useSocketConnect;

import type {
  IElectronAPI,
  IElectronModalParams,
  INotificationConstructorOptions,
} from './types';

export class ElectronActions {
  // eslint-disable-next-line no-useless-constructor
  constructor(private electronAPI: IElectronAPI | undefined, private linkValidation: (url: string) => string) { }

  private get isElectronApp() {
    return this.electronAPI !== undefined;
  }

  public showNotification(options: INotificationConstructorOptions & { extraData?: unknown }): void {
    if (!this.isElectronApp) {
      return;
    }
    const notificationOptionsString = JSON.stringify(options);
    this.electronAPI?.showNotification?.(notificationOptionsString);
  }

  public setAppBadge(badgeValue: number): void {
    if (!this.isElectronApp) {
      return;
    }
    this.electronAPI?.setAppBadge?.(String(badgeValue));
  }

  public callUpdateAppModal(params: IElectronModalParams) {
    if (!this.isElectronApp) {
      return false;
    }
    try {
      return this.electronAPI?.updateAppModal?.(JSON.stringify(params));
    } catch (err) {
      console.error('callUpdateAppModal error', err);
    }
  }

  public showSystemMessageModal(params: IElectronModalParams) {
    if (!this.isElectronApp) {
      return false;
    }
    try {
      return this.electronAPI?.showSystemMessage?.(JSON.stringify(params));
    } catch (err) {
      console.error('showSystemMessageModal error', err);
    }
  }

  public openExternalLink(url: string): void {
    if (!this.isElectronApp) {
      return;
    }
    try {
      const link = this.linkValidation(url);
      this.electronAPI?.openExternalLink?.(link);
    } catch (err) {
      console.error('Open external link error', err);
    }
  }

  public async downloadFile(url: string, name: string): Promise<string | undefined> {
    if (!this.isElectronApp) {
      return;
    }
    try {
      const data = JSON.stringify({ remoteFileUrl: url, name });
      return this.electronAPI?.downloadFile?.(data);
    } catch (err) {
      console.error('Download file error', err);
    }
  }

  public abortDownloadFile(url: string): void {
    if (!this.isElectronApp) {
      return;
    }
    return this.electronAPI?.abortDownloadFile?.(url);
  }

  public openFile(path: string): void {
    if (!this.isElectronApp) {
      return;
    }
    try {
      this.electronAPI?.openFile?.(path);
    } catch (err) {
      console.error('Open file error', err);
    }
  }

  public async askForPermission(permission: 'microphone'): Promise<boolean | undefined> {
    if (!this.isElectronApp) {
      return;
    }
    return this.electronAPI?.askForPermission?.(JSON.stringify({ name: permission }));
  }
}

import React from 'react';
import type { FormikErrors, FieldInputProps } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import StyledCustomInput from 'src/ui/components/CustomInput/CustomInput.styles';

type ChannelInputPropsType = {
  value?: string;
  onChangeValue?: React.ChangeEventHandler<HTMLInputElement>;
  titleLabel?: string;
  placeholder?: string;
  promptLabel?: string;
  startAdornment?: React.ReactNode;
  multiline?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  classes?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  error?: FormikErrors<string>;
  autocomplete?: 'on' | 'off';
  endAdornment?: React.ReactNode;
  touched?: boolean;
  isSelect?: boolean;
  rows?: number;
  textSize?: 'small' | 'medium' | 'large';
  handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  setAddButton?: () => void;
  fieldInputProps?: FieldInputProps<unknown>;
  customOnInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  uneditable?: boolean;
  inputSize?: 'l' | 'md';
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const CustomInput: React.FC<ChannelInputPropsType> = (props) => {
  const { t } = useTranslation('general');
  const defaultPlaceholder = t('defaultInputPlaceholder');

  const isLarge = props.textSize === 'large' || props.inputSize === 'l';
  const isMedium = props.textSize === 'medium' || props.inputSize === 'md';

  return (
    <StyledCustomInput
      fullWidth={props.fullWidth}
      fullHeight={props.fullHeight}
    >
      <TextField
        autoFocus={props.autoFocus}
        value={props.value}
        label={props.titleLabel}
        helperText={(props.error && props.touched) ? props.error : props.promptLabel}
        inputRef={props.inputRef}
        onChange={props.onChangeValue}
        onFocus={props.onFocus}
        onKeyDown={props.handleKeyDown}
        onInput={props.customOnInput}
        placeholder={props.placeholder ?? defaultPlaceholder}
        fullWidth={props.fullWidth}
        multiline={props.multiline}
        error={Boolean(props.error) && props.touched}
        margin="dense"
        rows={props.rows}
        classes={{
          root: classnames('custom-input__container', {
            'custom-input__container--disabled': props.uneditable,
          }),
        }}
        FormHelperTextProps={{
          classes: {
            root: 'custom-input__helper-text',
          },
        }}
        InputLabelProps={{
          classes: {
            root: classnames(
              'custom-input__label',
              {
                'custom-input__label_lg': isLarge,
                'custom-input__label_md': isMedium,
              },
            ),
            shrink: classnames(
              'custom-input__label-top',
              {
                'custom-input__label-top--error': props.error && props.touched,
                'custom-input__label-top_lg': isLarge,
              },
            ),
          },
        }}
        InputProps={{
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          classes: {
            root: classnames(
              'custom-input__wrapper',
              'custom-input__wrapper__multiline',
              { 'custom-input__wrapper--disabled': props.uneditable },
            ),
            input: classnames(
              'custom-input__input',
              'custom-input__input____multiline',
              props.classes,
              { 'custom-input__input_lg': isLarge },
              { 'custom-input__input_md': isMedium },
              { 'custom-input__textarea': props.multiline },
              { 'custom-input__disabled': props.uneditable },
            ),
            focused: 'custom-input__focused',
            notchedOutline: classnames(
              'custom-input__outline',
              'custom-input__outline__multiline',
              { 'custom-input__outline--error': props.error && props.touched },
              { 'custom-input__outline--select': props.isSelect },
            ),
          },
          disabled: props.uneditable,
        }}
        required={props.required}
        type={props.type ?? 'text'}
        inputProps={
          props.fieldInputProps
            ? {
              ...props.fieldInputProps,
              value: props.uneditable ? props.value : props.fieldInputProps.value,
            }
            : {
              maxLength: `${props.maxLength || 80}`,
              autoComplete: props.autocomplete || 'on',
              placeholder: props.placeholder || defaultPlaceholder,
              name: props.name,
              id: props.name,
            }}
        onBlur={props.onBlur}
      />
    </StyledCustomInput>
  );
};

export default CustomInput;

import { createSelector } from '@reduxjs/toolkit';
import _uniq from 'lodash/uniq';

import type { AppStateType } from 'src/store';
import type { IChannel, IPreviewMessage, IUser, MediaItemType, UserToChannelsType } from 'src/types';
import type { MediaStoreInnerItemKeyType } from './types';
import { UserPermissionsENUM } from 'src/types';
import { MessageTypeENUM } from 'src/types/chatTypes';
import { LOAD_MORE_ANCHOR_OFFSET } from '../constants';
import { groupMessages } from './chatSliceUtils';
import { sortByStrings } from 'src/utils/stringFormattingUtils';

const DEFAULT_ARRAY: unknown[] = [];

const selectChannels = createSelector(
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelsObject,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channels,
  ({ chatPageV2 }: AppStateType) => chatPageV2.dmChannels,
  ({ chatPageV2 }: AppStateType) => chatPageV2.archivedChannels,
  ({ main }: AppStateType) => main.user as IUser,
  ({ usersCollection }: AppStateType) => usersCollection.users.entities,
  (
    channelsObject,
    channels,
    dmChannels,
    archivedChannels,
    user,
    usersCollection,
  ) => {
    let notesChannel: IChannel | null = null;

    const formattedDmChannels = dmChannels
      .reduce<IChannel[]>((acc, channelId) => {
        const channel = channelsObject[channelId];

        if (user.companyId !== channel.companyId) {
          return acc;
        }

        if (channel.userToChannels?.length === 1) {
          notesChannel = channel;
          return acc;
        }

        const sortedUsers = [...(channel.userToChannels || [])].sort((a, b) => {
          const userA = usersCollection[a.userId!];
          const userB = usersCollection[b.userId!];

          const nameA = a?.userId === user?.userId ? '' : (userA?.fullName || '');
          const nameB = b?.userId === user?.userId ? '' : (userB?.fullName || '');
          return sortByStrings(nameA, nameB);
        });

        acc.push({
          ...channel,
          userToChannels: sortedUsers,
        });

        return acc;
      }, [])
      .sort((a, b) => {
        const userA = a?.userToChannels?.find((channelUser) => (channelUser.userId !== user.userId));
        const userB = b?.userToChannels?.find((channelUser) => (channelUser.userId !== user.userId));

        const nameA = userA?.userId ? usersCollection[userA.userId]?.fullName ?? '' : '';
        const nameB = userB?.userId ? usersCollection[userB.userId]?.fullName ?? '' : '';

        return sortByStrings(nameA, nameB);
      });

    if (notesChannel) {
      formattedDmChannels.unshift(notesChannel);
    }

    const shouldCheckForMembership = user.permissions?.some((permission) => {
      return permission.value === UserPermissionsENUM.chat__manageAllArchivedChannels;
    });

    const channelsList = channels.reduce<IChannel[]>((acc, channelId) => {
      if (user.companyId !== channelsObject[channelId].companyId) {
        return acc;
      }
      const channel = channelsObject[channelId];

      if (!shouldCheckForMembership) {
        acc.push(channel);
        return acc;
      }

      const isCurrentUserIncluded = channel.userToChannels?.some((userToChannel) => userToChannel.userId === user?.userId);
      if (isCurrentUserIncluded) {
        acc.push(channel);
      }

      return acc;
    }, []);

    const archivedChannelsByCompany = archivedChannels
      .map((channelId) => channelsObject[channelId])
      .filter((channel) => {
        return user.companyId === channel.companyId;
      });

    return {
      channels: channelsList,
      dmChannels: formattedDmChannels,
      archivedChannels: archivedChannelsByCompany,
    };
  },
);

const selectTotalChannelsUnreadCount = createSelector(
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelsObject,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channels,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelsMeta,
  ({ main }: AppStateType) => main.user as IUser,
  (channelsObject, channels, channelsMeta, user) => {
    return channels.reduce<number>((acc, channelId) => {
      if (user.companyId !== channelsObject[channelId].companyId) {
        return acc;
      }
      const channel = channelsObject[channelId];

      const isCurrentUserIncluded = channel.userToChannels?.some((userToChannel) => userToChannel.userId === user?.userId);
      if (isCurrentUserIncluded && channelsMeta[channelId]?.unreadMessagesCount) {
        return acc + channelsMeta[channelId].unreadMessagesCount;
      }

      return acc;
    }, 0);
  },
);

const selectTotalDmChannelsUnreadCount = createSelector(
  ({ chatPageV2 }: AppStateType) => chatPageV2.dmChannels,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelsMeta,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelsObject,
  ({ main }: AppStateType) => main.user as IUser,
  (channels, channelsMeta, channelsObject, user) => {
    return channels.reduce<number>((acc, channelId) => {
      const isVisible = channelsObject[channelId].userToChannels?.find((u) => u.userId === user.userId)?.isVisible;
      if (channelsMeta[channelId]?.unreadMessagesCount && isVisible) {
        return acc + channelsMeta[channelId].unreadMessagesCount;
      }

      return acc;
    }, 0);
  },
);

const selectChannelData = createSelector(
  (store: AppStateType, channelId: number) => channelId,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelsObject,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelDetailsObject,
  ({ usersCollection }: AppStateType) => usersCollection.users.entities,
  ({ main }: AppStateType) => main.user as IUser,
  (channelId, channelsObject, channelDetailsObject, usersObject, myUser) => {
    const channel = channelsObject[channelId];
    const channelDetails = channelDetailsObject[channelId];

    if (!channel || !channelDetails) {
      return null;
    }
    const isUserInChannel = channelDetails.usersToChannel.some((channelUser) => {
      return channelUser.userId === myUser.userId;
    });

    const grouppedMessages = groupMessages({
      messages: _uniq(channelDetails.messages),
      messagesObject: channelDetails.channelMessagesObject,
      pendingMessages: channelDetails.pendingMessages,
    });

    return {
      channel,
      channelUsers: channelDetails.usersToChannel,
      grouppedMessages,
      hasMoreUp: channelDetails.hasMoreUp,
      hasMoreDown: channelDetails.hasMoreDown,
      firstUnreadMessageId: channelDetails.firstUnreadMessageId,
      isUserInChannel,
      pinnedMessages: channelDetails.pinnedMessages,
      isLoadingUp: channelDetails.isLoadingUp,
      isLoadingDown: channelDetails.isLoadingDown,
      isPartiallyLoaded: channelDetails.isPartiallyLoaded,
      pinnedMessagesIds: channelDetails?.pinnedMessagesIds || [],
      pinnedMessagesLoadingStatus: channelDetails.pinnedMessagesLoadingStatus,
    };
  },
);

const selectMyLastMessage = createSelector(
  (store: AppStateType, channelId: number) => channelId,
  ({ main }: AppStateType) => main.user as IUser,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelDetailsObject,
  (channelId, user, channelDetailsObject) => {
    const channelDetails = channelDetailsObject[channelId];
    if (!channelDetails) {
      return null;
    }

    const messages = _uniq(channelDetails.messages)
      .map((m) => channelDetails.channelMessagesObject[m])
      .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));

    return messages.find((m) => m.authorId === user.userId && m.type === MessageTypeENUM.message && !m.deletedAt);
  },
);
const selectLastChannelMessage = createSelector(
  (store: AppStateType, channelId: number) => channelId,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelDetailsObject,
  (channelId, channelDetailsObject) => {
    const channelDetails = channelDetailsObject[channelId];
    if (!channelDetails) {
      return;
    }
    const messages = _uniq(channelDetails.messages)
      .map((m) => channelDetails.channelMessagesObject[m])
      .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));

    return messages[0];
  },
);

const selectMyLastByParentMessage = createSelector(
  (store: AppStateType, parentMessageId: number) => parentMessageId,
  ({ main }: AppStateType) => main.user as IUser,
  ({ chatPageV2 }: AppStateType) => chatPageV2.threads,
  (parentMessageId, user, threads) => {
    const threadDetails = threads[parentMessageId];
    if (!threadDetails) {
      return null;
    }

    const messages = _uniq(threadDetails.messages)
      .map((m) => threadDetails.messagesObject[m])
      .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));

    return messages.find((m) => m.authorId === user.userId && m.type === MessageTypeENUM.message && !m.deletedAt);
  },
);

const selectMessageData = createSelector(
  (store: AppStateType, messageId: number) => messageId,
  (store: AppStateType, messageId: number, channelId: number) => channelId,
  (store: AppStateType, messageId: number, channelId: number, parentMessageId?: number) => parentMessageId,
  (store: AppStateType) => store.main.user,
  (store: AppStateType) => store.chatPageV2.channelsObject,
  (store: AppStateType) => store.chatPageV2.channelDetailsObject,
  (store: AppStateType) => store.chatPageV2.threads,
  (store: AppStateType) => store.chatPageV2.channelsMeta,
  (messageId, channelId, parentMessageId, user, channelsObject, channelDetailsObject, threads, channelsMeta) => {
    const userId = user!.userId;

    if (parentMessageId) {
      const threadData = threads[parentMessageId];
      const message = threadData.messagesObject[messageId];
      if (!message) {
        return {};
      }

      const lastViewedMessageTime = threadData.userToThreads?.find((i) => i.userId === userId)?.lastViewedMessageTime;
      const isNew = new Date(lastViewedMessageTime!) <= new Date(message.createdAt);
      const isMyMessage = message.authorId === userId;
      const isSystem = message.type === MessageTypeENUM.action;

      return {
        isTopAnchor: false,
        isBottomAnchor: false,
        isUnread: isNew && !isMyMessage && !isSystem,
        isNew,
        isMyMessage,
        isSystem,
      };
    }

    const channelData = channelsObject[channelId];
    const channelDetails = channelDetailsObject[channelId];

    const message = channelDetails?.channelMessagesObject[messageId];
    if (!message) {
      return {};
    }

    const lastViewedMessageTime = channelData.userToChannels?.find((i) => i.userId === userId)?.lastViewedMessageTime;
    const isNew = new Date(lastViewedMessageTime!) <= new Date(message.createdAt);
    const isMyMessage = message.authorId === userId;
    const isSystem = message.type === MessageTypeENUM.action;

    const ANCHOR_OFFSET = LOAD_MORE_ANCHOR_OFFSET;
    const topAnchorMessageId = channelDetails.hasMoreUp
      ? channelDetails.messages[ANCHOR_OFFSET - 1]
      : null;

    const [lastItem] = channelDetails.messages.slice(-1);
    const bottomAnchorMessageId = channelDetails.hasMoreDown
      ? lastItem
      : null;

    const threadMeta = channelsMeta[channelId]?.threads?.[messageId];

    return {
      isTopAnchor: topAnchorMessageId === messageId,
      isBottomAnchor: bottomAnchorMessageId === messageId,
      isUnread: isNew && !isMyMessage && !isSystem,
      threadMeta,
      isMyMessage,
      isNew,
      isSystem,
    };
  },
);

const selectThreadData = createSelector(
  (store: AppStateType, channelId?: number) => channelId,
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelDetailsObject,
  (store: AppStateType) => store.chatPageV2.openedThread,
  (store: AppStateType) => store.chatPageV2.threads,
  (channelId, channelDetailsObject, openedThread, threads) => {
    if (!channelId || channelId !== openedThread.channelId) {
      return {};
    }
    const parentMessage = channelDetailsObject[channelId].channelMessagesObject[openedThread.parentMessageId!];
    const parentMessageData = threads[parentMessage?.messageId];

    if (!parentMessage) {
      return {};
    }

    const grouppedMessages = parentMessageData ? groupMessages({
      messages: _uniq(parentMessageData.messages),
      messagesObject: parentMessageData.messagesObject,
      pendingMessages: parentMessageData.pendingMessages,
    }) : [];

    return {
      parentMessage,
      grouppedMessages,
      messagesCount: parentMessage.childMessages?.length || 0,
      parentMessageId: parentMessage.messageId,
      isLoading: !parentMessageData ? openedThread.isLoading : false,
      firstUnreadMessageId: parentMessageData ? parentMessageData.firstUnreadMessageId : null,
    };
  },
);

const selectMessage = createSelector(
  (store: AppStateType, messageId: number | null) => messageId,
  (store: AppStateType, messageId: number | null, channelId?: number | null) => channelId,
  (store: AppStateType, messageId: number | null, channelId?: number | null, parentMessageId?: number | null) => parentMessageId,
  (store: AppStateType) => store.chatPageV2.channelDetailsObject,
  (store: AppStateType) => store.chatPageV2.threads,
  (messageId, channelId, parentMessageId, channelDetailsObject, threads) => {
    if (!messageId) {
      return null;
    }

    if (parentMessageId) {
      return threads[parentMessageId]?.messagesObject[messageId] || null;
    }

    if (!channelId) {
      return null;
    }

    return channelDetailsObject[channelId]?.channelMessagesObject[messageId] || null;
  },
);

const selectChannelMediaItems = createSelector(
  (store: AppStateType, params: { channelId: number }) => params.channelId,
  (store: AppStateType, params: { parentMessageId?: number }) => params.parentMessageId,
  (store: AppStateType, params: { inputType: MediaStoreInnerItemKeyType }) => params.inputType,
  (store: AppStateType, params: { hideSent?: boolean }) => params.hideSent,
  (store: AppStateType) => store.chatPageV2.mediaItems,
  (channelId, parentMessageId, inputType, hideSent, mediaItems) => {
    if ((!channelId && typeof channelId !== 'number') || !mediaItems?.[channelId]?.[inputType]) {
      return [];
    }
    let mediaFiles: MediaItemType[];

    if (parentMessageId) {
      mediaFiles = Object.values(mediaItems)
        .flatMap((channel) => Object.values(channel))
        .filter((item) => item.parentMessageId === parentMessageId)
        .flatMap((item) => item.files);
    } else {
      mediaFiles = mediaItems[channelId][inputType]?.files || [];
    }

    if (hideSent) {
      return mediaFiles.filter((file) => !file.isSent);
    }
    return mediaFiles;
  },
);

const selectUnreadThreadsCount = createSelector(
  (store: AppStateType) => store.chatPageV2.channelsMeta,
  (channelsMeta) => {
    let count = 0;
    Object.values(channelsMeta).forEach((channelMeta) => {
      Object.values(channelMeta.threads).forEach((threadMeta) => {
        count += threadMeta.unreadMessagesCount;
      });
    });

    return count;
  },
);

const selectThreadDataByParentMessage = createSelector(
  (_: AppStateType, params: { parentMessageId: number }) => params.parentMessageId,
  (_: AppStateType, params: { channelId: number }) => params.channelId,
  (store: AppStateType) => store.chatPageV2.channelDetailsObject,
  (store: AppStateType) => store.chatPageV2.threads,
  (parentMessageId, channelId, channelDetailsObject, threads) => {
    const parentMessage = channelDetailsObject[channelId]?.channelMessagesObject[parentMessageId];
    const threadsMessages = threads[parentMessageId];
    if (!parentMessage) {
      return {};
    }

    const grouppedMessages = threadsMessages ? groupMessages({
      messages: _uniq(threadsMessages.messages),
      messagesObject: threadsMessages.messagesObject,
      pendingMessages: threadsMessages.pendingMessages,
    }) : [];

    const unreadMessageItem = threadsMessages.firstUnreadMessageId &&
      threadsMessages.messagesObject[threadsMessages.firstUnreadMessageId]
      ? threadsMessages.messagesObject[threadsMessages.firstUnreadMessageId]
      : undefined;

    return {
      parentMessage,
      grouppedMessages,
      messagesCount: parentMessage.childMessages?.length || 0,
      parentMessageId: parentMessage.messageId,
      firstUnreadMessageId: threadsMessages ? threadsMessages.firstUnreadMessageId : null,
      unreadMessageDate: unreadMessageItem
        ? unreadMessageItem.createdAt
        : undefined,
    };
  },
);

const selectChannelUsers = createSelector(
  (store: AppStateType, params: { channelId: number }) => params.channelId,
  (store: AppStateType) => store.chatPageV2.channelDetailsObject,
  (channelId, channelDetailsObject) => {
    const channelDetails = channelDetailsObject[channelId];
    if (!channelDetails) {
      return DEFAULT_ARRAY as UserToChannelsType[];
    }
    return channelDetails.usersToChannel;
  },
);

const selectPreviewMessages = createSelector(
  (store: AppStateType) => store.chatPageV2.previewMessages.messages,
  (store: AppStateType, ids?: number[]) => ids,
  (messages, ids) => {
    if (!ids?.length) {
      return DEFAULT_ARRAY as IPreviewMessage[];
    }
    return ids.map((id) => messages[id]).filter((message): message is IPreviewMessage => Boolean(message));
  },
);

export default {
  selectChannels,
  selectChannelData,
  selectMessageData,
  selectThreadData,
  selectMessage,
  selectChannelMediaItems,
  selectUnreadThreadsCount,
  selectThreadDataByParentMessage,
  selectChannelUsers,
  selectTotalChannelsUnreadCount,
  selectTotalDmChannelsUnreadCount,
  selectMyLastMessage,
  selectMyLastByParentMessage,
  selectPreviewMessages,
  selectLastChannelMessage,
};

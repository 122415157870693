import type { ThemeOptions, PaletteColor } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import lighten from 'polished/lib/color/lighten';
import darken from 'polished/lib/color/darken';
import readableColor from 'polished/lib/color/readableColor';

import legacyMainTheme from 'src/legacy/ui/styles/StyledComponentsTheme/themes/main';

export const createColor = (params: Partial<PaletteColor> & Pick<PaletteColor, 'main'>): PaletteColor => ({
  light: params.light || lighten(0.05, params.main),
  main: params.main,
  dark: params.dark || darken(0.2, params.main),
  contrastText: params.contrastText || readableColor(params.main),
});

export const MAIN_FONT_SIZE = 16;
export const MAIN_LINE_HEIGHT = 26;

export const lightThemeObject = {
  legacy: legacyMainTheme,
  colors: {
    default: createColor({ main: '#000000' }),
  },
  screen: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1440,
  } as const,
  font: {
    family: {
      main: "'Mulish', 'Lato'",
      emoji: "'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Emoji', Times, Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, serif, LastResort",
      fallback: 'sans-serif',
    },
    weight: {
      /** 300 */
      xxs: 300,
      /** 400 */
      xs: 400,
      /** 500 */
      sm: 500,
      /** 600 */
      md: 600,
      /** 700 */
      lg: 700,
      /** 800 */
      xlg: 800,
    },
    size: {
      /** 10px */
      xxs: '0.625rem',
      /** 12px */
      xs: '0.75rem',
      /** 14px */
      sm: '0.875rem',
      /** 16px */
      md: '1rem',
      /** 18px */
      lg: '1.125rem',
      /** 20px */
      xlg: '1.25rem',
    },
  },
  transition: {
    0: '0ms',
    1: '100ms',
    2: '300ms',
    3: '500ms',
    4: '1000ms',
  },
  round: {
    0: '0',
    1: '5px',
    2: '15px',
    3: '30px',
  },
};

interface IPalette {
  mode: 'light' | 'dark';
  common: {
    black: string;
    white: string;
  };
  primary: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    main: '#FF7A50';
    mainGradient: string;
    hoverGradient: string;
    contrastText: string;
    border: string;
  };
  alert: {
    300: string;
    400: string;
    500: string;
    main: string;
  };
  secondary: PaletteColor;
  tertiary: {
    primary: string;
    contrastText: string;
  };
  status: {
    icon: {
      active: string;
      registered: string;
      disabled: string;
    };
    background: {
      active: string;
      registered: string;
      disabled: string;
    };
    text: {
      active: string;
      registered: string;
      disabled: string;
    };
  };
  error: PaletteColor & { extraLight: string };
  warning: PaletteColor;
  info: PaletteColor;
  success: PaletteColor;
  grey: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    900: string;
    A100: string;
    A200: string;
    A300: string;
    A400: string;
    disabled: string;
  };
  green: {
    500: string;
  };
  white: {
    200: string;
    300: string;
    400: string;
    600: string;

    A1: string;
    A2: string;
    A3: string;
    A4: string;
    A5: string;
  };
  contrastThreshold: number;
  tonalOffset: number;
  text: {
    disabled: string;
    icon: string;
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
  };
  divider: string;
  background: {
    gradient: string;
    paper: string;
    default: string;
    hover: string;
    header: string;
    footerMenu: string;
    linkPreview: string;
  };
  action: {
    active: string;
    hover: string;
    hoverOpacity: number;
    selected: string;
    selectedOpacity: number;
    disabled: string;
    disabledBackground: string;
    disabledBorder: string;
    disabledOpacity: number;
    focus: string;
    focusOpacity: number;
    activatedOpacity: number;
    fadedBackground: string;
  };
  navButton: {
    primary: string;
  };
  iconButton: {
    background: string;
  };
  input: {
    background: string;
    text: {
      light: string;
    };
    border: {
      default: string;
      focused: string;
    };
  };
  select: {
    cardBackground: string;
    optionBackground: {
      primary: string;
      secondary: string;
    };
  };
  button: {
    disabled: {
      background: string;
      fontColor: string;
    };
    outlined: {
      focusBorder: string;
    };
    contained: {
      hoverBorder: string;
      focusBorder: string;
    };
  };
  switch: {
    checked: {
      track: {
        base: string;
        disabled: string;
        hover: string;
        focus: string;
        border: string;
      };
      thumb: {
        base: string;
        disabled: string;
        hover: string;
        focus: string;
      };
    };
    unchecked: {
      track: {
        base: string;
        disabled: string;
        hover: string;
        focus: string;
        border: string;
      };
      thumb: {
        base: string;
        disabled: string;
        hover: string;
        focus: string;
      };
    };
  };
  tabs: {
    root: string;
    selected: string;
    indicator: string;
    indicatorSpan: string;
    border: string;
  };
  tooltip: {
    background: string;
    border: string;
    color: string;
  };
  menu: {
    background: string;
    border: string;
    item: string;
  };
  mark: {
    background: string;
  };
  reactions: {
    pressed: {
      background: string;
      border: string;
      color: string;
    };
    unpressed: {
      background: string;
      border: string;
      color: string;
    };
    hovered: {
      background: string;
      pressedBackground: string;
    };
  };
  toolbarMessage: {
    boxShadowColor: string;
  };
  avatarsBackground: string[];
  scrollBlockGradient: {
    top: string;
    bottom: string;
  };
  linearLoader: {
    line: string;
  };
  fullImageFooterBackground: string;
  pinnedBoxShadow: {
    primary: string;
    secondary: string;
  };
  headerBoxShadow: string;
  badge: {
    success: string;
    secondary: string;
  };
  emojiPicker: {
    rgbColor: string;
    rgbAccent: string;
    rgbInput: string;
    colorBorderOver: string;
    colorBorder: string;
    rgbBackground: string;
  };
}

export interface IOverridedThemeOptions extends ThemeOptions {
  palette: Omit<ThemeOptions['palette'], 'primary'> & IPalette;
}

const palette: IPalette = {
  mode: 'light',
  common: {
    black: '#000',
    white: '#FFF',
  },
  primary: {
    50: '#FFFAF5',
    100: '#FFF2E5',
    200: '#FFD8C1',
    300: '#FFB8A2',
    400: '#FF9270',
    500: '#FF7A50',
    600: '#FF6534',
    main: '#FF7A50',
    mainGradient: 'linear-gradient(221.32deg, #FFA15E 5.66%, #FF6636 100%)',
    hoverGradient: 'linear-gradient(221.32deg, #F48737 5.66%, #EE5B2D 100%)',
    contrastText: '#FFF',
    border: '#FFE2C7',
  },
  alert: {
    300: '#FF908E',
    400: '#FF7573',
    500: '#F6605D',
    main: '#F6605D',
  },
  secondary: {
    main: '#404A60',
    light: '#586581',
    dark: '#1D222D',
    contrastText: '#FFFFFF',
  },
  tertiary: {
    primary: '#FF7573',
    contrastText: '#FFFFFF',
  },
  status: {
    icon: {
      active: '#60DF43',
      registered: '#FFC530',
      disabled: '#A7AEBF',
    },
    background: {
      active: '#F0F9F2',
      registered: '#FCFAF1',
      disabled: '#EFF3F6',
    },
    text: {
      active: '#1F5619',
      registered: '#936C00',
      disabled: '#586581',
    },
  },
  error: {
    main: '#F3554C',
    light: '#FF8676',
    dark: '#B9131D',
    extraLight: '#FFCCC9',
    contrastText: '#F6F9FA',
  },
  warning: {
    main: '#ed6c02',
    light: '#ff9800',
    dark: '#e65100',
    contrastText: '#fff',
  },
  info: {
    main: '#0288d1',
    light: '#03a9f4',
    dark: '#01579b',
    contrastText: '#fff',
  },
  success: {
    main: '#60DF43',
    light: '#4caf50',
    dark: '#1b5e20',
    contrastText: '#fff',
  },
  grey: {
    50: '#1D222D',
    100: '#404A60',
    200: '#586581',
    300: '#727B8C',
    400: '#8A909C',
    500: '#A7AEBF',
    600: '#D4DEE8',
    700: '#586581',
    900: '#1D222D',
    A100: '#838383',
    A200: '#BDBDBD',
    A300: '#F2F4F6',
    A400: '#f7f7f7',
    disabled: '#D9D9E2',
  },
  white: {
    200: '#F6F6F6',
    300: '#EFF3F6',
    400: '#E8EBEE',
    600: '#D4DEE8',

    A1: '#E8EBEE',
    A2: '#EFF3F6',
    A3: '#F6F6F6',
    A4: '#FAFAFA',
    A5: '#FFFFFF',
  },
  green: {
    500: '#4DC730',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#1D222D',
    secondary: '#586581',
    tertiary: '#111727',
    quaternary: '#A7AEBF',
    disabled: '#CDCBD2',
    icon: '',
  },
  divider: '#E8EBEE',
  background: {
    gradient: 'linear-gradient(179.12deg, #FFFFFF -13.9%, #ECEFF1 134.02%)',
    default: '#FAFAFA',
    paper: '#FFFFFF',
    hover: '#EFF3F7',
    header: '#1976D2',
    footerMenu: '#F2F4F6',
    linkPreview: '#e6eaed',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.03)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledBorder: 'rgba(255, 255, 255, 0.1)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
    fadedBackground: 'rgba(255, 255, 255, 0.8)',
  },
  navButton: {
    primary: '#FF7A50',
  },
  iconButton: {
    background: '#EFF3F7',
  },
  input: {
    background: '#EFF3F6',
    text: {
      light: '#A7AEBF',
    },
    border: {
      default: '#E8EBEE',
      focused: '#FFB8A1',
    },
  },
  select: {
    cardBackground: '#D4DEE8',
    optionBackground: {
      primary: '#E8EBEE',
      secondary: '#FAFAFA',
    },
  },
  button: {
    disabled: {
      background: '#E8EBEE',
      fontColor: '#CDCBD2',
    },
    outlined: {
      focusBorder: '0px 0px 0px 1px #FF7A50',
    },
    contained: {
      hoverBorder: '0px 0px 0px 1px #FF7A50',
      focusBorder: '0px 0px 0px 2px #FF6534',
    },
  },
  switch: {
    checked: {
      track: {
        base: 'linear-gradient(221.32deg, #FFA15E 5.66%, #FF6636 100%)',
        disabled: '#D9D9E2',
        hover: 'linear-gradient(221.32deg, #F48737 5.66%, #EE5B2D 100%)',
        focus: 'linear-gradient(221.32deg, #FFA15E 5.66%, #FF6636 100%)',
        border: '#D4DEE8',
      },
      thumb: {
        base: '#FFFFFF',
        disabled: '#FAFAFA',
        hover: '#FFFFFF',
        focus: '#FFFFFF',
      },
    },
    unchecked: {
      track: {
        base: '#E8EBEE',
        disabled: '#D9D9E2',
        hover: '#D4DEE8',
        focus: '#E8EBEE',
        border: '#D4DEE8',
      },
      thumb: {
        base: '#FFFFFF',
        disabled: '#FAFAFA',
        hover: '#FFFFFF',
        focus: '#FFFFFF',
      },
    },
  },
  tabs: {
    root: '#586581',
    selected: '#FF7A50',
    indicator: '#FF7A50',
    indicatorSpan: '#D4DEE8',
    border: '#E8EBEE',
  },
  tooltip: {
    background: '#1D222D',
    border: '#E8EBEE',
    color: '#E8EBEE',
  },
  menu: {
    background: '#fff',
    border: '#E8EBEE',
    item: '#111727',
  },
  mark: {
    background: '#D4DEE8',
  },
  reactions: {
    pressed: {
      background: '#FFF2E5',
      border: '#FF7A50',
      color: '#FF7A50',
    },
    unpressed: {
      background: '#f6f6f6',
      border: '#FF7A50',
      color: '#586581',
    },
    hovered: {
      background: '#FFF2E5',
      pressedBackground: '#FFD8C1',
    },
  },
  toolbarMessage: {
    boxShadowColor: 'rgba(203, 149, 111, 0.2)',
  },
  avatarsBackground: [
    '#D9C2EF',
    '#E3C2EF',
    '#E9C2EF',
    '#EFC2E5',
    '#EFC2D8',
    '#EFC2D2',
    '#EFC2CA',
    '#EFC2C2',
    '#EFCAC2',
    '#EFD2C2',
    '#EFD8C2',
    '#E8D5B9',
    '#ECDCB4',
    '#E9E2A7',
    '#DCE3AB',
    '#D5E8AE',
    '#D7ECB4',
    '#CDECB4',
    '#BFECB4',
    '#B4ECBD',
    '#B4ECC7',
    '#B4ECD1',
    '#B4ECDB',
    '#B4ECE9',
    '#B4E5EC',
    '#B4DFEC',
    '#B4DBEC',
    '#B4D1EC',
    '#B4C4EC',
    '#B4B6EC',
    '#CBB4DF',
    '#D5B6E0',
    '#DBBBE0',
    '#DCB5D3',
    '#E0B6CA',
    '#DCADBE',
    '#DBAFB7',
    '#E3BABA',
    '#E7C1B8',
    '#DFC1B1',
    '#DFC9B4',
    '#D8C5AA',
    '#DCCBA1',
    '#DDD7A1',
    '#D0D99B',
    '#C9DD9F',
    '#C4D9A2',
    '#B8D89F',
    '#ACD6A1',
    '#A3E2AD',
    '#9DD9B1',
    '#9CD7BB',
    '#9EDAC8',
    '#9FDAD6',
    '#A8D6DC',
    '#9FC9D7',
    '#A1C7D8',
    '#A2BFDA',
    '#A3B3DC',
    '#B1B3E0',
  ],
  scrollBlockGradient: {
    top: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    bottom: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
  },
  linearLoader: {
    line: 'rgba(64, 74, 96, 0.5)',
  },
  fullImageFooterBackground: 'rgba(0, 0, 0, 0.35)',
  pinnedBoxShadow: {
    primary: 'rgba(0, 0, 0, 0.03)',
    secondary: 'rgba(203, 149, 111, 0.2)',
  },
  headerBoxShadow: 'rgba(0, 0, 0, 0.05)',
  badge: {
    success: '#60DF43',
    secondary: '#8A909C',
  },
  emojiPicker: {
    rgbColor: '88, 101, 129',
    rgbAccent: '255, 122, 80',
    rgbInput: '239, 243, 246',
    colorBorderOver: 'rgb(232,235,238)',
    colorBorder: 'rgb(232,235,238)',
    rgbBackground: '255, 255, 255',
  },
};

export type ThemeType = typeof lightThemeObject & IOverridedThemeOptions;

export const lightMaterialTheme: ThemeType = {
  ...lightThemeObject,
  breakpoints: {
    values: lightThemeObject.screen,
  },
  palette,
  typography: {
    htmlFontSize: MAIN_FONT_SIZE,
    fontFamily: 'inherit',
    fontSize: MAIN_FONT_SIZE,
    fontWeightLight: lightThemeObject.font.weight.sm,
    fontWeightRegular: lightThemeObject.font.weight.sm,
    fontWeightMedium: lightThemeObject.font.weight.md,
    fontWeightBold: lightThemeObject.font.weight.lg,
    button: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: lightThemeObject.font.size.sm,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    h1: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.lg,
      fontSize: lightThemeObject.font.size.xlg,
      lineHeight: '25.1px',
      letterSpacing: 'normal',
    },
    h2: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.lg,
      fontSize: lightThemeObject.font.size.lg,
      lineHeight: '23.5px',
      letterSpacing: 'normal',
    },
    h3: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.lg,
      fontSize: lightThemeObject.font.size.md,
      lineHeight: '18.5px',
      letterSpacing: 'normal',
    },
    h4: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: lightThemeObject.font.size.sm,
      lineHeight: '17.57px',
      letterSpacing: 'normal',
    },
    h5: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.sm,
      fontSize: lightThemeObject.font.size.sm,
      lineHeight: '19px',
      letterSpacing: 'normal',
    },
    h6: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.sm,
      fontSize: lightThemeObject.font.size.xs,
      lineHeight: '15.06px',
      letterSpacing: 'normal',
    },
    subtitle1: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    subtitle2: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body1: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body2: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    caption: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.xlg,
      fontSize: lightThemeObject.font.size.xxs,
      lineHeight: '10.5px',
      letterSpacing: 'normal',
    },
    overline: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'uppercase',
    },
  },
  zIndex: {
    mobileStepper: 1,
    appBar: 1,
    drawer: 10,
    modal: 250,
    snackbar: 1,
    tooltip: 300,
    speedDial: 20,
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 36,
          height: 22,
          padding: 0,
          '.MuiTouchRipple-root': {
            opacity: 0,
          },
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: palette.switch.checked.thumb.focus,
              '& + .MuiSwitch-track': {
                background: palette.switch.checked.track.base,
                opacity: 1,
              },
              '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 16,
                height: 16,
                color: palette.switch.checked.thumb.base,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: palette.switch.checked.track.disabled,
              },
              '&.Mui-disabled + .MuiSwitch-thumb': {
                color: palette.switch.checked.thumb.disabled,
              },
              '&.Mui-focusVisible + .MuiSwitch-track': {
                backgroundColor: palette.switch.checked.track.focus,
              },
              '&.Mui-focusVisible + .MuiSwitch-thumb': {
                color: palette.switch.checked.thumb.focus,
              },
            },
            '&.Mui-focusVisible + .MuiSwitch-track': {
              backgroundColor: palette.switch.unchecked.track.focus,
            },
            '&.Mui-focusVisible + .MuiSwitch-thumb': {
              color: palette.switch.unchecked.thumb.focus,
            },
            '&:hover': {
              '&.Mui-checked': {
                '& + .MuiSwitch-track': {
                },
                '& .MuiSwitch-thumb': {
                  color: palette.switch.checked.thumb.hover,
                },
              },
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: palette.switch.unchecked.track.disabled,
            },
            '&.Mui-disabled + .MuiSwitch-thumb': {
              color: palette.switch.unchecked.thumb.disabled,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 16,
            height: 16,
            color: palette.switch.unchecked.thumb.base,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            height: '90%',
            background: palette.switch.unchecked.track.base,
            opacity: 1,
            transition: {
              duration: 500,
            },
          },
          '&:hover': {
            '& .MuiSwitch-track': {
              background: palette.switch.unchecked.track.hover,
            },
            '& .MuiSwitch-thumb': {
              color: palette.switch.unchecked.thumb.hover,
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '36px',
          color: palette.tabs.root,
          '& .MuiTabs-indicator': {
            backgroundColor: palette.tabs.indicator,
          },
          '& .MuiTabs-indicatorSpan': {
            width: '100%',
            backgroundColor: palette.tabs.indicatorSpan,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: lightThemeObject.font.size.sm,
          fontWeight: lightThemeObject.font.weight.md,
          color: palette.tabs.root,
          opacity: 1,
          minHeight: '20px',
          padding: '8px 16px',
          '&.Mui-selected': {
            color: palette.tabs.selected,
            fontWeight: lightThemeObject.font.weight.lg,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '8px 12px',
          background: palette.tooltip.background,
          color: palette.tooltip.color,
          fontSize: lightThemeObject.font.size.sm,
          borderRadius: 8,
          whiteSpace: 'break-spaces',
        },
        arrow: {
          color: palette.tooltip.background,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: palette.background.paper,
          border: `1px solid ${palette.menu.border}`,
          borderRadius: '12px',
          boxShadow: 'none',
          marginLeft: 7,
        },
        list: {
          // marginTop: 2,
          backgroundColor: palette.background.paper,
          border: `1px solid ${palette.tabs.border}`,
          // borderRadius: 6,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          '& .MuiMenuItem-root:first-child': {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: palette.primary[100],
            },
          },
          '& .Mui-selected': {
            backgroundColor: 'transparent',
          },
          '& .filter-item': {
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
          },
          '& .item-wide': {
            width: 194,
          },
          '& .item-narrow': {
            width: 75,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: lightThemeObject.font.size.sm,
          fontWeight: lightThemeObject.font.weight.md,
          color: palette.menu.item,
          padding: '8px 16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          gap: '8px',
          background: '#fff',
          '&:hover': {
            background: '#FFFAF5',
          },
          '.MuiTouchRipple-root': {
            opacity: 0,
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#FFFAF5',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          padding: '8px 16px',
          borderRadius: '6px',
          color: palette.primary[500],
          '& + .Mui-disabled': {
            borderColor: 'inherit',
            color: palette.button.disabled.fontColor,
          },
          '.MuiTouchRipple-root': {
            opacity: 0,
          },
          '&:hover': {
            background: 'inherit',
            borderColor: 'inherit',
            color: palette.primary[400],
          },
        },
        contained: {
          background: palette.primary.mainGradient,
          color: palette.primary.contrastText,
          boxShadow: 'none',

          '&:hover': {
            background: palette.primary.hoverGradient,
            color: palette.primary.contrastText,
            boxShadow: palette.button.contained.hoverBorder,
          },
          '&:focus': {
            background: palette.primary.mainGradient,
            boxShadow: palette.button.contained.focusBorder,
            color: palette.primary.contrastText,
          },
          '&.Mui-disabled': {
            border: `1px solid ${palette.button.disabled.background}`,
            background: palette.button.disabled.background,
            color: palette.button.disabled.fontColor,
          },
        },
        containedError: {
          background: palette.error.dark,
          border: `1px solid ${palette.error.dark}`,
          color: palette.error.contrastText,

          '&:hover': {
            background: palette.error.main,
            borderColor: palette.error.main,
          },
          '&:active': {
            background: palette.error.dark,
            borderColor: palette.error.dark,
          },
          '&:focus': {
            background: palette.error.dark,
            borderColor: palette.error.dark,
          },
          '& + .Mui-disabled': {
            border: `1px solid ${palette.button.disabled.background}`,
            background: palette.button.disabled.background,
            color: palette.button.disabled.fontColor,
          },
        },
        outlined: {
          border: `1px solid ${palette.primary[500]}`,
          color: palette.primary[500],
          boxShadow: 'none',

          '&:hover': {
            borderColor: palette.primary[400],
            color: palette.primary[400],
            background: 'inherit',
            boxShadow: 'none',
          },
          '&:active': {
            borderColor: palette.primary[400],
            color: palette.primary[400],
            background: 'inherit',
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: palette.button.outlined.focusBorder,
            color: palette.primary[500],
            borderColor: palette.primary[500],
          },
          '& + .Mui-disabled': {
            border: `1px solid ${palette.button.disabled.background}`,
            color: palette.button.disabled.fontColor,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontSize: `${lightThemeObject.font.size.sm}`,
          color: `${palette.grey[400]}`,
          '&.Mui-selected': {
            background: 'none',
            color: `${palette.primary[400]}`,
          },
          '.MuiTouchRipple-root': {
            opacity: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: palette.white.A2,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          boxShadow: `0px 2px 10px ${palette.grey.A300}`,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: lightThemeObject.font.weight.md,
        },
      },
    },
  },
};

export default createTheme(lightMaterialTheme, lightThemeObject);

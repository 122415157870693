import { createAsyncThunk } from '@reduxjs/toolkit';
import _uniq from 'lodash/uniq';

import type { ICompany, IUser } from 'src/types';
import storage from 'src/utils/storage';
import { resetToken } from 'src/api/http';
import authApi from 'src/api/authApi';
import helpers from 'src/utils/helpers';
import type { AppDispatchType, AppStateType } from '../store';
import { mainSliceActions } from './mainSlice.reducer';
import config from 'src/config';
import { userCollectionThunks } from '../usersCollection/thunks';

const authorize = createAsyncThunk<IUser | null, undefined, {
  state: AppStateType;
  dispatch: AppDispatchType;
}>(
  'main/authorizeUser',
  async (_, { dispatch, getState }) => {
    const storedAccessToken = storage.authToken.get();
    if (!storedAccessToken) {
      return null;
    }

    const workspacesPromise = dispatch(getWorkspaces()).unwrap().catch(() => ({}));

    const response = await authApi.getMe();
    let user = response.data.payload;
    let subdomain = helpers.getSubdomain();
    if (subdomain !== user.company!.subdomen! && config.isSubdomainsEnabled) {
      const availableWorkspaces = await workspacesPromise;
      const availableAccount = Object.values(availableWorkspaces).find(
        (item) => item.subdomen === subdomain,
      );

      if (!availableAccount) {
        subdomain = user.company!.subdomen;
        helpers.replaceSubdomain(subdomain, {
          companyHash: user.company!.companyHash,
        });
      } else {
        const response = await authApi.changeCompany(availableAccount.companyId);
        user = response.data.payload;

        storage.authToken.set(response.data.meta.authToken);
        storage.refreshToken.set(response.data.meta.refreshToken);
        resetToken();

        const store = getState() as AppStateType;
        const selectedWorkspaces = _uniq([...store.main.selectedWorkspaces, availableAccount.companyId]);
        dispatch(mainSliceActions.setSelectedWorkspaces(selectedWorkspaces));
      }
    }
    await dispatch(userCollectionThunks.getUserCollectionThunk({})).unwrap();
    return user;
  },
);

const getWorkspaces = createAsyncThunk<Record<number, ICompany>, IUser[] | undefined>(
  'main/getWorkspaces',
  async (users) => {
    let accounts: IUser[] = users || [];
    if (!users) {
      const response = await authApi.getMyAccounts();
      accounts = response.data.payload;
    }

    const companyMap = accounts.reduce<Record<number, ICompany>>((acc, user) => {
      if (!user.company) {
        return acc;
      }
      acc[user.company.companyId] = user.company;
      return acc;
    }, {});
    return companyMap;
  },
);

const changeCompany = createAsyncThunk(
  'main/changeCompany',
  async (workspaceId: number) => {
    const { data } = await authApi.changeCompany(workspaceId);

    storage.authToken.set(data.meta.authToken);
    storage.refreshToken.set(data.meta.refreshToken);

    return {
      user: data.payload,
    };
  },
);

export default {
  authorize,
  getWorkspaces,
  changeCompany,
};

import type { IUser, ConfirmationUserDataType } from 'src/types';
import type { ICompany } from 'src/types/generalTypes';
import storage from 'src/utils/storage';
import http, { resetToken } from './http';
import type { ResponseType } from './http';

const authPath = '/auth';

type SignInPayloadType = IUser & { company: ICompany };
type SignInTokensPairType = { authToken: string; refreshToken: string };

const signIn = async (data: { email: string; code: string }) => {
  const response = await http.post<ResponseType<SignInPayloadType, SignInTokensPairType>>(`${authPath}/sign-in`, data);

  storage.authToken.set(response.data.meta.authToken);
  storage.refreshToken.set(response.data.meta.refreshToken);
  resetToken();
  return response;
};

const signUp = async (data: {
  firstName: string;
  lastName: string;
  workspaceName: string;
  email: string;
  phone: string;
  key: string;
}) => {
  const response = await http.post<ResponseType<IUser>>(`${authPath}/sign-up`, data);

  storage.authToken.set(response.data.meta.authToken);
  storage.refreshToken.set(response.data.meta.refreshToken);
  resetToken();
  return response;
};

const getMe = () => {
  return http.get<ResponseType<IUser>>(`${authPath}/me`);
};

const generateDomain = (data: { name: string }) => {
  return http.post<ResponseType<string>>(`${authPath}/domain`, data);
};

const checkDomainName = (domainName: string) => {
  return http.post<ResponseType<{ isExists: boolean }>>(`${authPath}/domain/${domainName}`);
};

const checkIsDomainCreated = (subdomain: string) => {
  return http.post(`${authPath}/check-availability-domain`, { subdomain });
};

const requestCode = (data: { email: string }) => {
  return http.post<ResponseType<string>>(`${authPath}/email/send`, data);
};

const confirmCode = (data: { code: string; email: string; key: string }) => {
  return http.post<ResponseType<{ isTrue: boolean }>>(`${authPath}/email/check-code`, data);
};

const getUnconfimedUser = (token: string) => {
  return http.get<ResponseType<ConfirmationUserDataType>>(`${authPath}/unconfirmed-user`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

const confirmNewUser = (data: { code: string }, token: string) => {
  return http.post<ResponseType<IUser, { authToken: string; refreshToken: string }>>(`${authPath}/confirm-new-user`, data, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

const requestCodeWithCheckUser = (data: { email: string }) => {
  return http.post<ResponseType<string>>(`${authPath}/email/check-and-send`, data);
};

const getMyAccounts = () => {
  return http.get<ResponseType<IUser[]>>(`${authPath}/my-accounts`);
};

const changeCompany = (id: number) => {
  return http.post<ResponseType<IUser>>(`${authPath}/change-company`, { companyId: id });
};

export default {
  signIn,
  getMe,
  generateDomain,
  requestCode,
  confirmCode,
  checkDomainName,
  signUp,
  requestCodeWithCheckUser,
  getMyAccounts,
  changeCompany,
  getUnconfimedUser,
  confirmNewUser,
  checkIsDomainCreated,
};
